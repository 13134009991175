const user = {
  gender: {
    MAN: "man",
    WOMAN: "woman",
  },
  lookingFor: {
    SUGARBABY: "sugarbaby",
    SUGARMOMMY: "sugardaddy",
    SUGARDADDY: "sugardaddy",
    MALE_SUGARBABY: "sugarbaby",
  },
};

export default user;
