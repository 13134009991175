// General
import "./login.scss";
import { useEffect, useState, useRef, forwardRef } from "react";
// Services
import {
  useAuthSigninMutation,
  useLazyGetTokenQuery,
} from "../../services/data.service";
import { emailRegex } from "../../services/regex.service";
// Assets
import BrandLogo from "../../assets/logo/candybook-uppercase.png";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmail, updatePassword } from "../../redux/store/signin";
// react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
// Material UI
import {
  Box,
  TextField,
  Snackbar,
  Button,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// i18next
import { useTranslation } from "react-i18next";

const Login = () => {
  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const recaptchaRef = useRef();

  // API variables
  const [
    authSignin,
    {
      data: authSigninData,
      error: authSigninErrorData,
      isLoading: authSigninLoading,
      isSuccess: authSigninSuccess,
      isError: authSigninError,
    },
  ] = useAuthSigninMutation();
  const [
    getToken,
    {
      data: getTokenData,
      error: getTokenErrorData,
      isFetching: getTokenFetching,
      isLoading: getTokenLoading,
      isSuccess: getTokenSuccess,
      isError: getTokenError,
    },
  ] = useLazyGetTokenQuery();

  // Redux variables
  const { queryString } = useSelector((state) => state.public);
  const email = useSelector((state) => state.signin.email);
  const password = useSelector((state) => state.signin.password);
  const rememberMe = useSelector((state) => state.signin.rememberMe);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(updateEmail(""));
    dispatch(updatePassword(""));
  }, []);

  // Lifecycle | Check for update | Auth Signin API Response
  useEffect(() => {
    if (authSigninLoading) {
    } else if (authSigninSuccess) {
      switch (authSigninData?.status) {
        case 0:
          let redirectUrl = `${process.env["REACT_APP_REDIRECT_URL"]}${authSigninData?.data?.legacy_token}&email=${email}`;
          window.location.replace(redirectUrl);
          break;
        case -1:
          // Error
          onOpenSnackbar();
        default:
          break;
      }
    } else if (authSigninError) {
      onOpenSnackbar();
    }
  }, [authSigninLoading, authSigninSuccess, authSigninError]);

  // Event Handlers | Button
  const onSignin = async () => {
    window.dataLayer.push({
      event: "candybook-login",
      send_to: "AW-11048749483/LNv4CLvtnocYEKuTupQp",
    });

    // Google Recaptcha Token
    const token = await recaptchaRef.current.executeAsync();

    const obj = {
      email,
      password,
      auth_method: "email_password",
      gre_token: token,
    };
    authSignin(obj);
  };

  // Event Handlers | MUI Text Field
  const onEmailChange = (e) => {
    dispatch(updateEmail(e.target.value));
  };
  const onEmailFocus = (e) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (e) => {
    dispatch(updatePassword(e.target.value));
  };
  const onPasswordFocus = (e) => {
    setPasswordFocus(true);
  };

  // Event Handlers | MUI Snackbar
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (emailValid && passwordValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (!emailFocus) {
      valid = false;
      error = false;
    } else if (emailFocus && email === "") {
      setEmailErrorMessage(t("signup.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("signup.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (!passwordFocus) {
      valid = false;
      error = false;
    } else if (passwordFocus && password === "") {
      setPasswordErrorMessage(t("signup.password_is_required"));
      valid = false;
      error = true;
    } else if (passwordFocus && password.length < 6) {
      setPasswordErrorMessage(
        t("signup.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
  }, [
    email,
    password,
    emailFocus,
    passwordFocus,
    emailErrorMessage,
    passwordErrorMessage,
  ]);

  // Snackbar Alert Component
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Box
      id="login-page"
      className="login-container"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="sugarbook-logo-container">
          <img className="sugarbook-logo" src={BrandLogo} />
        </div>

        <div className="email-form-field-container">
          <TextField
            className="email-form-field custom-text-field"
            required
            value={email}
            error={emailError}
            onChange={onEmailChange}
            label={t("login.email_address")}
            placeholder={t("login.email_address")}
            helperText={emailErrorMessage}
            variant="standard"
            onFocus={onEmailFocus}
          />
        </div>

        <div className="password-form-field-container">
          <TextField
            className="password-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={password}
            error={passwordError}
            onChange={onPasswordChange}
            label={t("login.password")}
            type="password"
            placeholder={t("login.password")}
            helperText={passwordErrorMessage}
            variant="standard"
            onFocus={onPasswordFocus}
          />
        </div>

        <div className="recaptcha-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env["REACT_APP_RECAPTCHA_V3_SITE_KEY"]}
            theme="light"
          />
        </div>

        <div className="button-container">
          <Button
            className={`login-button ${isFormValid() ? "" : "disabled-button"}`}
            variant="text"
            onClick={onSignin}
            disabled={!isFormValid()}
          >
            {authSigninLoading ? (
              <CircularProgress className="button-spinner" size={24} />
            ) : (
              t("login.login")
            )}
          </Button>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom" + "center"}
      >
        <Alert severity="error">{authSigninErrorData?.data?.message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
