// General
import "./join-now.scss";
import { Link } from "react-router-dom";
// Assets
import JoinNowImage from "../../../assets/others/stockphotos/join-now.png";
// Static Data
import route from "../../../const/route";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const JoinNow = () => {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-join-now-subcomponent">
      <div className="gradient-background">
        <div className="image-container">
          <img className="image" src={JoinNowImage} />
        </div>

        <div className="join-now-description-container">
          <div className="header">
            {t("landing.start_your_modern_relationship_today")}
          </div>

          <Link
            className="join-now-button-container"
            to={{ pathname: route.join.path, search: queryString }}
          >
            <div className="join-now-button">{t("landing.join_free_now")}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JoinNow;
