// General
import "./footer.scss";
// i18next
import { useTranslation } from "react-i18next";

const Footer = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="footer">
      <div className="text">Candybook 2022. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
