// General
import "./landing.scss";
import { useLocation } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { updateQueryString } from "../../redux/store/public";
// Components
import Hero from "./hero/hero";
import FeaturedIn from "./featured-in/featured-in";
import MeetYourDate from "./meet-your-date/meet-your-date";
import Features from "./features/features";
import MultiDevice from "./multi-device/multi-device";
import JoinNow from "./join-now/join-now";
import Footer from "../shared/footer/footer";

const Landing = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Utility variables
  let { search } = useLocation();
  dispatch(updateQueryString(search));

  return (
    <div id="landing-page">
      <Hero />

      <FeaturedIn />

      <MultiDevice />

      <Features />

      <MeetYourDate />

      <JoinNow />

      <Footer />
    </div>
  );
};

export default Landing;
