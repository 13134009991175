// Assets
import feature1 from "../assets/logo/landing/feature/feature-1.png";
import feature2 from "../assets/logo/landing/feature/feature-2.png";
import feature3 from "../assets/logo/landing/feature/feature-3.png";
import feature4 from "../assets/logo/landing/feature/feature-4.png";

const landing = {
  features: [
    {
      image: feature1,
      header: "landing.honesty_transparency",
      description: "landing.honesty_transparency_description",
    },
    {
      image: feature2,
      header: "landing.verified_profiles",
      description: "landing.verified_profiles_description",
    },
    {
      image: feature3,
      header: "landing.remain_discreet",
      description: "landing.remain_discreet_description",
    },
    {
      image: feature4,
      header: "landing.highly_rated_for_a_reason",
      description: "landing.highly_rated_for_a_reason_description",
    },
  ],
};

export default landing;
