// General
import { createSlice } from "@reduxjs/toolkit";
// Static Data
import { API_CONFIG_HEADERS } from "../../const/api";

const initialState = {
  apiToken: "",
  userProfile: {},
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updateApiToken: (state, action) => {
      state.apiToken = action.payload;
      API_CONFIG_HEADERS.SPI_HEADERS.authorization = action.payload;
      API_CONFIG_HEADERS.COM_HEADERS.token = action.payload;
    },
    updateUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

export const { updateApiToken, updateUserProfile } = accountSlice.actions;

export default accountSlice.reducer;
