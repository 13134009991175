// General
import { createSlice } from "@reduxjs/toolkit";
// Static Data
import { API_CONFIG_HEADERS } from "../../const/api";

const initialState = {
  queryString: "",
};

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    updateQueryString: (state, action) => {
      state.queryString = action.payload;
    },
  },
});

export const { updateQueryString } = publicSlice.actions;

export default publicSlice.reducer;
