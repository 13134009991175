// General
import "./meet-your-date.scss";
// Assets
import MeetYourDateImageV2 from "../../../assets/others/stockphotos/met-your-date-v2.png";
// i18next
import { useTranslation } from "react-i18next";

const MeetYourDate = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-meet-your-date-subcomponent">
      <div className="meet-your-date-description-container">
        <div className="mobile-center-container">
          <div className="header">{t("landing.meet_your_sweet_date")}</div>

          <div className="text">
            {t("landing.meet_your_sweet_date_description")}
          </div>
          <div className="text">
            {t("landing.meet_your_sweet_date_description_2")}
          </div>
        </div>
      </div>

      <div className="meet-your-date-image-container">
        <img className="meet-your-date" src={MeetYourDateImageV2} />
      </div>
    </div>
  );
};

export default MeetYourDate;
