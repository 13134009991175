// General
import "./features.scss";
// Static Data
import landing from "../../../const/landing";
// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const Features = () => {
  // Constant variables
  const featuresData = landing.features;

  const isMobile = useMediaQuery("(max-width: 520px)");

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-features-subcomponent">
      <div className="features-label">
        {t("landing.enjoy_our_unique_features")}
      </div>

      <div className="feature-list-container">
        {isMobile ? (
          <div className="mobile-view">
            <Carousel
              showArrows={true}
              autoPlay={true}
              interval={5000}
              showStatus={false}
              showThumbs={false}
            >
              {featuresData.map((feature, index) => (
                <div className="feature-container" key={index}>
                  <div className="feature-image-container">
                    <img className="feature-image" src={feature.image} />
                  </div>

                  <div className="feature-header">{t(`${feature.header}`)}</div>

                  <div className="feature-description">
                    {t(`${feature.description}`)}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="desktop-view">
            {featuresData.map((feature, index) => (
              <div className="feature-container" key={index}>
                <div className="feature-image-container">
                  <img className="feature-image" src={feature.image} />
                </div>

                <div className="feature-header">{t(`${feature.header}`)}</div>

                <div className="feature-description">
                  {t(`${feature.description}`)}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Features;
