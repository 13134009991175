// General
import "./navbar.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// Assets
import BrandLogo from "../../../assets/logo/candybook.png";
import BrandLogoUpper from "../../../assets/logo/candybook-uppercase.png";
import BurgerMenuIcon from "../../../assets/icons/burger-menu.png";
// Static Data
import route from "../../../const/route";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// i18next
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const { queryString } = useSelector((state) => state.public);

  // i18next variables
  const { t } = useTranslation();

  const routes = [
    { routeName: t("navbar.about_us"), routePath: route.aboutUs.path },
    { routeName: t("navbar.terms"), routePath: route.terms.path },
    { routeName: t("navbar.join_free_now"), routePath: route.join.path },
    { routeName: t("navbar.login"), routePath: route.login.path },
  ];
  const [state, setState] = React.useState({
    top: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer("top", false)}
      onKeyDown={toggleDrawer("top", false)}
    >
      <List>
        {routes.map((text, index) => (
          <div key={index}>
            <ListItem key={text.routeName} disablePadding>
              <ListItemButton onClick={toggleDrawer("top", false)}>
                <Link
                  className="burger-menu-list-link"
                  to={{ pathname: text.routePath, search: queryString }}
                >
                  <ListItemText
                    className="burger-menu-list-text"
                    primary={text.routeName}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <div id="navbar">
      <div className="space-around">
        <div className="desktop-view">
          <Link
            className="brand-logo-container"
            to={{ pathname: route.landing.path, search: queryString }}
          >
            <img className="brand-logo" src={BrandLogoUpper} />
          </Link>

          <span className="spacer"></span>

          <div className="navigation-right-container">
            <Link
              className="info-item"
              to={{ pathname: route.aboutUs.path, search: queryString }}
            >
              {t("navbar.about_us")}
            </Link>

            <Link
              className="info-item"
              to={{ pathname: route.terms.path, search: queryString }}
            >
              {t("navbar.terms")}
            </Link>

            <Link
              className="info-item"
              to={{ pathname: route.join.path, search: queryString }}
            >
              {t("navbar.join_free_now")}
            </Link>

            <Link
              className="info-item"
              to={{ pathname: route.login.path, search: queryString }}
            >
              {t("navbar.login")}
            </Link>
          </div>
        </div>

        <div className="mobile-view">
          <div className="left-container"></div>

          <Link
            className="brand-logo-container"
            to={{ pathname: route.landing.path, search: queryString }}
          >
            <img className="brand-logo" src={BrandLogoUpper} />
          </Link>

          <React.Fragment key="top">
            <div
              className="burger-menu-container"
              onClick={toggleDrawer("top", true)}
            >
              <img className="burger-menu" src={BurgerMenuIcon} />
            </div>
          </React.Fragment>

          <Drawer
            anchor="top"
            open={state["top"]}
            onClose={toggleDrawer("top", false)}
          >
            {list("left")}
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
