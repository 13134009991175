// General
import "./about-us.scss";
import { Link } from "react-router-dom";
// Static Data
import route from "../../const/route";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Footer from "../shared/footer/footer";

const AboutUs = () => {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="about-us-page">
      <div className="top-container">
        <div className="max-width-container">
          <div className="header">{t("about_us.about_us")}</div>

          <div className="text">{t("about_us.about_us_description")}</div>
          <div className="text">{t("about_us.about_us_description_2")}</div>
          <div className="text">{t("about_us.about_us_description_3")}</div>

          <Link
            className="button-container"
            to={{ pathname: route.join.path, search: queryString }}
          >
            <div className="button">{t("about_us.join_free_today")}</div>
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
