// General
import "./multi-device.scss";
// Assets
import multiDeviceDesktop from "../../../assets/others/stockphotos/multi-device-desktop.png";
import multiDeviceMobile from "../../../assets/others/stockphotos/multi-device-mobile.png";
// i18next
import { useTranslation } from "react-i18next";

const MultiDevice = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-multi-device-subcomponent">
      <div className="header">
        {t("landing.making_dating_effortless_accross_all_devices")}
      </div>
      <img className="desktop-img" src={multiDeviceDesktop} />
      <img className="mobile-img" src={multiDeviceMobile} />
    </div>
  );
};

export default MultiDevice;
