// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  rememberMe: false,
};

export const signinSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },
    updateRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },

    // Utility Functions
    resetSigninStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateEmail,
  updatePassword,
  updateRememberMe,

  // Utility Functions
  resetSigninStore,
} = signinSlice.actions;

export default signinSlice.reducer;
