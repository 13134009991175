// General
import "./lifestyle-signup.scss";
import * as React from "react";
import { Link } from "react-router-dom";
// Static Data
import route from "../../../const/route";
import user from "../../../const/user";
//Redux
import { useSelector } from "react-redux";
// Material UI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const LifestyleSignup = () => {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);

  const monthlySpendingHabitList = user.lifestyle.monthlySpendingHabit;
  const netWorthList = user.lifestyle.netWorth;
  const annualIncomeList = user.lifestyle.annualIncome;
  const heightList = [];
  const bodyTypeList = user.lifestyle.bodyType;
  const ethnicityList = user.lifestyle.ethnicity;
  const eyeColorList = user.lifestyle.eyeColor;
  const hairColorList = user.lifestyle.hairColor;

  for (let i = 130; i < 230; i++) {
    heightList.push(i);
  }

  const [monthlySpendingHabit, setMonthlySpendingHabit] = React.useState("");
  const [netWorth, setNetWorth] = React.useState("");
  const [annualIncome, setAnnualIncome] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [bodyType, setBodyType] = React.useState("");
  const [ethnicity, setEthnicity] = React.useState("");
  const [eyeColor, setEyeColor] = React.useState("");
  const [hairColor, setHairColor] = React.useState("");

  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "monthlySpendingHabit":
        setMonthlySpendingHabit(event.target.value);
        break;
      case "netWorth":
        setNetWorth(event.target.value);
        break;
      case "annualIncome":
        setAnnualIncome(event.target.value);
        break;
      case "height":
        setHeight(event.target.value);
        break;
      case "bodyType":
        setBodyType(event.target.value);
        break;
      case "ethnicity":
        setEthnicity(event.target.value);
        break;
      case "eyeColor":
        setEyeColor(event.target.value);
        break;
      case "hairColor":
        setHairColor(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div id="lifestyle-signup-page">
      <div className="max-width-container">
        <div className="steps-label">Step 2 / 3</div>

        <div className="step-description-label">
          Your lifestyle & appearance
        </div>

        <div className="monthly-habit-form-field-container">
          <TextField
            className="monthly-habit-form-field"
            select
            label="Monthly Spending Habit"
            value={monthlySpendingHabit}
            onChange={handleSelectChange("monthlySpendingHabit")}
            variant="standard"
          >
            {monthlySpendingHabitList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="net-worth-form-field-container">
          <TextField
            className="net-worth-form-field"
            select
            label="Net Worth"
            value={netWorth}
            onChange={handleSelectChange("netWorth")}
            variant="standard"
          >
            {netWorthList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="annual-income-form-field-container">
          <TextField
            className="annual-income-form-field"
            select
            label="Annual Income"
            value={annualIncome}
            onChange={handleSelectChange("annualIncome")}
            variant="standard"
          >
            {annualIncomeList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="flex-container">
          <div className="height-form-field-container">
            <TextField
              className="height-form-field"
              select
              label="Height"
              value={height}
              onChange={handleSelectChange("height")}
              variant="standard"
            >
              {heightList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="body-type-form-field-container">
            <TextField
              className="body-type-form-field"
              select
              label="Body Type"
              value={bodyType}
              onChange={handleSelectChange("bodyType")}
              variant="standard"
            >
              {bodyTypeList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div className="ethnicity-form-field-container">
          <TextField
            className="ethnicity-form-field"
            select
            label="Ethnicity"
            value={ethnicity}
            onChange={handleSelectChange("ethnicity")}
            variant="standard"
          >
            {ethnicityList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="flex-container">
          <div className="eye-color-form-field-container">
            <TextField
              className="eye-color-form-field"
              select
              label="Eye Color"
              value={eyeColor}
              onChange={handleSelectChange("eyeColor")}
              variant="standard"
            >
              {eyeColorList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="hair-color-form-field-container">
            <TextField
              className="hair-color-form-field"
              select
              label="Hair Color"
              value={hairColor}
              onChange={handleSelectChange("hairColor")}
              variant="standard"
            >
              {hairColorList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div className="button-container">
          <div className="next-button-container">
            <Link
              className="next-button inactive-button"
              to={{ pathname: route.detailSignup.path, search: queryString }}
            >
              Next
            </Link>
          </div>

          <div className="skip-button-container">
            <Link
              className="skip-button"
              to={{ pathname: route.detailSignup.path, search: queryString }}
            >
              Skip For Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifestyleSignup;
