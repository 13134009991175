// General
import "./detail-signup.scss";
import * as React from "react";
import { Link } from "react-router-dom";
// Static Data
import route from "../../../const/route";
import user from "../../../const/user";
//Redux
import { useSelector } from "react-redux";
// Material UI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const DetailSignup = () => {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);

  const educationList = user.detail.education;
  const relationshipList = user.detail.relationship;
  const childrenList = user.detail.children;
  const smokingList = user.detail.smoking;
  const drinkingList = user.detail.drinking;

  const [occupation, setOccupation] = React.useState("");
  const [education, setEducation] = React.useState("");
  const [relationship, setRelationship] = React.useState("");
  const [children, setChildren] = React.useState("");
  const [smoking, setSmoking] = React.useState("");
  const [drinking, setDrinking] = React.useState("");

  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "occupation":
        setOccupation(event.target.value);
        break;
      case "education":
        setEducation(event.target.value);
        break;
      case "relationship":
        setRelationship(event.target.value);
        break;
      case "children":
        setChildren(event.target.value);
        break;
      case "smoking":
        setSmoking(event.target.value);
        break;
      case "drinking":
        setDrinking(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div id="detail-signup-page">
      <div className="max-width-container">
        <div className="steps-label">Step 3 / 3</div>

        <div className="step-description-label">You're almost finished!</div>

        <div className="occupation-form-field-container">
          <TextField
            className="occupation-form-field"
            label="Occupation"
            value={occupation}
            onChange={handleSelectChange("occupation")}
            variant="standard"
          ></TextField>
        </div>

        <div className="education-form-field-container">
          <TextField
            className="education-form-field"
            select
            label="Education"
            value={education}
            onChange={handleSelectChange("education")}
            variant="standard"
          >
            {educationList.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="flex-container">
          <div className="relationship-form-field-container">
            <TextField
              className="relationship-form-field"
              select
              label="Relationship"
              value={relationship}
              onChange={handleSelectChange("relationship")}
              variant="standard"
            >
              {relationshipList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="children-form-field-container">
            <TextField
              className="children-form-field"
              select
              label="Children"
              value={children}
              onChange={handleSelectChange("children")}
              variant="standard"
            >
              {childrenList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div className="flex-container">
          <div className="smoking-form-field-container">
            <TextField
              className="smoking-form-field"
              select
              label="Smoking"
              value={smoking}
              onChange={handleSelectChange("smoking")}
              variant="standard"
            >
              {smokingList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="drinking-form-field-container">
            <TextField
              className="drinking-form-field"
              select
              label="Drinking"
              value={drinking}
              onChange={handleSelectChange("drinking")}
              variant="standard"
            >
              {drinkingList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div className="button-container">
          <div className="next-button-container">
            <Link
              className="next-button inactive-button"
              to={{
                pathname: route.emailVerification.path,
                search: queryString,
              }}
            >
              Next
            </Link>
          </div>

          <div className="skip-button-container">
            <Link
              className="skip-button"
              to={{
                pathname: route.emailVerification.path,
                search: queryString,
              }}
            >
              Skip For Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSignup;
