// General
import "./redirect.scss";
import { useEffect } from "react";
// Services
import { useLazyGetTokenQuery } from "../../services/data.service";
// Environment
import environment from "../../environment/environment";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateToken } from "../../redux/store/redirect";

const Redirect = () => {
  // API variables
  const [
    getToken,
    {
      data: getTokenData,
      error: getTokenErrorData,
      isFetching: getTokenFetching,
      isLoading: getTokenLoading,
      isSuccess: getTokenSuccess,
      isError: getTokenError,
    },
  ] = useLazyGetTokenQuery();

  // Redux variables
  const apiToken = useSelector((state) => state.account.apiToken);
  const token = useSelector((state) => state.redirect.token);
  const email = useSelector((state) => state.signup.email);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    window.dataLayer.push({
      event: "conversion",
      send_to: "AW-11048749483/LNv4CLvtnocYEKuTupQp",
    });

    setTimeout(() => {
      // getToken();

      let redirectUrl = `${process.env["REACT_APP_REDIRECT_URL"]}${apiToken}&email=${email}`;
      window.location.replace(redirectUrl);
    }, 2000);
  }, []);

  // Lifecycle | Check for update | Get Token API Response
  useEffect(() => {
    if (getTokenFetching || getTokenLoading) {
    } else if (getTokenSuccess) {
      dispatch(updateToken(getTokenData.data.token));

      let redirectUrl = `${process.env["REACT_APP_REDIRECT_URL"]}${getTokenData?.data?.token}`;
      window.location.replace(redirectUrl);
    } else if (getTokenError) {
    }
  }, [getTokenFetching, getTokenLoading, getTokenSuccess, getTokenError]);

  return (
    <div id="redirect-page">
      <div className="center-container">
        <div className="header">Thank You</div>
        <div className="text">Please wait while we setup your account</div>
      </div>
    </div>
  );
};

export default Redirect;
