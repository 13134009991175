// Environment
import env from "../environment/environment";

// App Key
export const DEVICE_TYPE = "web"; // Remember to add new entries in backend so taht API don't throw error
export const DEVICE_LANGUAGE = "en";
export const APP_IDENTIFIER = "candy-book.com"; // Remember to add new entries in backend so taht API don't throw error

export const CUSTOM_USER_AGENT = `Sugarbook PWA/${env.appVersion} (${APP_IDENTIFIER})`;
export const FB_APP_ID = "143826786234468";
export const SP_PUBLIC_KEY = "pk_test_Cjd6sp4joFb5IKO8WNPsp9MN";

// Headers
export const API_CONFIG_HEADERS = {
  SB_API_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${env.appVersion}`,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": `${env.apiAccessToken}`,
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${env.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
  },
  COM_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "display-mode": "browser",
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${env.appVersion}`,
    "sb-development": 1,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": `${env.apiAccessToken}`,
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${env.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
    token: "",
  },
  SPI_HEADERS: {
    // "Content-Type": "application/json",
    Accept: "application/json",
    "display-mode": "browser",
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${env.appVersion}`,
    "SB-Language": `${DEVICE_LANGUAGE}`,
    platform: `${DEVICE_TYPE}`,
    authorization: "",
  },
};
