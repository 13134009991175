// General
import "./email-verification.scss";
// Assets
import MailLogo from "../../../assets/icons/mail.svg";

const EmailVerification = () => {
  return (
    <div id="email-verification-page">
      <div className="top-container">
        <div className="verify-email-label">Verify your email address</div>

        <div className="email-logo-container">
          <img className="email-logo" src={MailLogo} />
        </div>
      </div>

      <div className="bottom-container">
        <div className="body-container">
          <div className="label-1">We have sent an email to</div>
          <div className="email-label">asga@asas.com</div>
          <div className="label-1">
            Please click on the provided verification link to verify your email
            address
          </div>
          <div className="resend-label">Resend Verification Email</div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
