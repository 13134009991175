// General
import "./terms.scss";
// Static Data
import terms from "../../const/terms";
// Components
import Footer from "../shared/footer/footer";

const Terms = () => {
  const termsData = terms;

  return (
    <div id="terms-page">
      <div className="top-container">
        <div className="max-width-container">
          <div className="header">Terms</div>

          <div className="terms">
            {termsData.map((term, index) => (
              <div key={index}>
                {term.category === "header" && (
                  <div className="terms-header">{term.content}</div>
                )}
                {term.category === "subheader" && (
                  <div className="terms-subheader">{term.content}</div>
                )}
                {term.category === "point" && (
                  <div className="terms-point">{term.content}</div>
                )}
              </div>
            ))}
          </div>

          <div className="text"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
