// General
import "./join.scss";
import { useNavigate } from "react-router-dom";
// Static Data
import route from "../../const/route";
// Enums
import user from "../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGender, updateLookingFor } from "../../redux/store/signup";
// Material UI
import Button from "@mui/material/Button";
// i18next
import { useTranslation } from "react-i18next";

function Join() {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);
  const { gender, lookingFor } = useSelector((state) => state.signup);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const navigate = useNavigate();

  // Event Handlers | Button
  const onNextPage = () => {
    navigate(route.accountSignup.path + queryString);
  };

  // Event Handlers | MUI
  const onGenderSelect = (e) => {
    dispatch(updateGender(e));

    if (e === user.gender.MAN) {
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
    } else if (e === user.gender.WOMAN) {
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    }
  };
  const onLookingForSelect = (e) => {
    dispatch(updateLookingFor(e));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (gender !== "" && lookingFor !== "") {
      valid = true;
    }

    return valid;
  };

  return (
    <div id="join-page" className="join-container">
      <div className="max-width-container">
        <div className="first-container">
          <div className="header">{t("signup.i_am_a")}</div>
          <div className="body-container">
            <div
              className={`body-label ${gender === "man" ? "selected" : ""}`}
              onClick={() => onGenderSelect(user.gender.MAN)}
            >
              {t("signup.opt_man")}
            </div>
            <div
              className={`body-label ${gender === "woman" ? "selected" : ""}`}
              onClick={() => onGenderSelect(user.gender.WOMAN)}
            >
              {t("signup.opt_woman")}
            </div>
          </div>
        </div>

        {false && gender !== "" && (
          <div className="second-container">
            <div className="header">{t("signup.im_looking_for")}</div>
            {gender === "man" && (
              <div className="body-container">
                <div
                  className={`body-label ${
                    lookingFor === "sugarbaby" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARBABY)}
                >
                  {t("signup.opt_sugar_baby")}
                </div>
                <div
                  className={`body-label ${
                    lookingFor === "sugardaddy" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARMOMMY)}
                >
                  {t("signup.opt_sugar_mommy")}
                </div>
              </div>
            )}
            {gender === "woman" && (
              <div className="body-container">
                <div
                  className={`body-label ${
                    lookingFor === "sugardaddy" ? "selected" : ""
                  }`}
                  onClick={() => onLookingForSelect(user.lookingFor.SUGARDADDY)}
                >
                  {t("signup.opt_sugar_daddy")}
                </div>
                <div
                  className={`body-label ${
                    lookingFor === "sugarbaby" ? "selected" : ""
                  }`}
                  onClick={() =>
                    onLookingForSelect(user.lookingFor.MALE_SUGARBABY)
                  }
                >
                  {t("signup.opt_male_sugar_baby")}
                </div>
              </div>
            )}
          </div>
        )}

        <Button
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onNextPage}
          disabled={!isFormValid()}
        >
          {t("signup.continue")}
        </Button>
      </div>
    </div>
  );
}

export default Join;
