// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gender: "",
  lookingFor: "",
  wantToBe: "",
  email: "",
  password: "",
  name: "",
  dob: "",
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updateLookingFor: (state, action) => {
      state.lookingFor = action.payload;

      switch (`${state.gender}-${state.lookingFor}`) {
        case "man-sugarbaby":
          state.wantToBe = "sugardaddy";
          break;
        case "man-sugardaddy":
          state.wantToBe = "sugarbaby";
          break;
        case "woman-sugardaddy":
          state.wantToBe = "sugarbaby";
          break;
        case "woman-sugarbaby":
          state.wantToBe = "sugardaddy";
          break;
        default:
          break;
      }
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateDob: (state, action) => {
      state.dob = action.dob;
    },
  },
});

export const {
  updateGender,
  updateLookingFor,
  updateEmail,
  updatePassword,
  updateName,
  updateDob,
} = signupSlice.actions;

export default signupSlice.reducer;
