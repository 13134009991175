// General
import "./error-page.scss";

const ErrorPage = () => {
  return (
    <div id="error-page">
      <div className="error-label">404 Not Found</div>
    </div>
  );
};

export default ErrorPage;
