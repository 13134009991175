const terms = [
  { category: "header", content: "1. INTRODUCTION" },
  {
    category: "subheader",
    content:
      '1.1. This Terms of Service ("Agreement") forms an agreement that governs any user\'s (referred as "you" or "your") access to and use of the services provided by a Cyprus entity, Sovrnt Ltd. (Company Registration No. HE395258) of (collectively referred to as "Sovrnt"). Sovrnt is collectively referred as "Company" or "we" or "us" or "our" in this Agreement.',
  },
  {
    category: "subheader",
    content:
      '1.2. The Company owns and operates various electronic portal including, but not limited to, an online website at https://candybook.com.my and “Candybook” mobile application (collectively referred as "Platforms") on which we provide an online dating platform for the users ("Service"). You understand that we are merely a social networking platform and our users and members are not agents, employees, servants or anyhow related to us.',
  },
  {
    category: "subheader",
    content:
      "1.3. This Agreement may be modified by the Company in its sole discretion at any time, and any such modifications will be posted on the Platforms. You are responsible to regularly review the Agreement and your continued use of the Service after the posting of modifications to this Agreement will constitute your acceptance of the revised Agreement.  Please read this Agreement carefully before accessing or using the Service. You must read, agree with and accept all of the terms and conditions contained in this Agreement, which includes those terms and conditions expressly set out below and those incorporated by reference before you use any part of the Service.",
  },
  {
    category: "subheader",
    content:
      "1.4. By using the Service, you expressly agree to be bound by this Agreement, regardless of whether or not you register as a member or paid subscriber. The Company is willing to license the Service to you for your use only upon the condition that you accept all the terms contained herein.",
  },
  {
    category: "subheader",
    content:
      "1.5. Your access to or use of the Service indicates that you have read, understood and consented to this Agreement and accept all of its associated terms. If you do not accept all of those conditions, please do not access to or use the Platforms or Service.",
  },
  { category: "header", content: "2. PROPRIETARY RIGHTS" },
  {
    category: "subheader",
    content:
      "2.1 Company owns and retains all proprietary rights in the Platform and the Service. The Platform and Service contains copyrighted material, trademarks and other proprietary information, which includes all the content, trademarks, trade names, services marks, and intellectual property.",
  },
  { category: "header", content: "3. ACCOUNTS" },
  { category: "header", content: "Eligibility" },
  {
    category: "subheader",
    content:
      "3.1. YOU CANNOT USE THE PLATFORMS OR THE SERVICE IF YOU ARE NOT AT LEAST EIGHTEEN (18) YEARS OF AGE AND/OR THE AGE OF MAJORITY AND LEGAL CONSENT IN THE JURISDICTION IN WHICH YOU LIVE OR RESIDE AND HAVE THE LEGAL CAPACITY TO ENTER INTO THIS AGREEMENT.",
  },
  {
    category: "subheader",
    content:
      "3.2. Whilst the Company requires all users or members to be at least eighteen (18) years old, we are not responsible or liable for any misrepresentations regarding a user’s or member’s age or for the unauthorized use of the Platforms or Service by a minor.",
  },
  { category: "header", content: "Membership" },
  {
    category: "subheader",
    content:
      "3.3. This Agreement is an electronic contract that sets out the legally binding terms you must accept to use the Service and to become a “Member” of the Service. For the purpose of this Agreement, the term “Member” means a person who provides information to the Company on the Platforms to participate in the Service in any manner, whether such person uses the Service as a Member for free or a paying subscriber. The former is allowed access to a minimal level of the Service’s features free of charge, whereas the latter is provided with access to additional features of the Service which are not available to the former upon the payment of the relevant fees.",
  },
  {
    category: "subheader",
    content:
      "3.4. To access and use the Service, you must first create a verified account by providing, among others, your username, email address, mobile number and password, and select a subscription, if applicable. Unless specifically stated otherwise, the account is personal to you and cannot be shared with or transferred to any third party. You must keep your username and password confidential, and you are solely and fully responsible for all activities that occur under your username and password.",
  },
  {
    category: "subheader",
    content:
      "3.5. You further acknowledge that the Company does not routinely undertake background checks, verify information provided or conduct criminal screenings of the Member. The Company reserves the right to conduct investigations and background checks on all members, to confirm you are not in breach of any warranty and to determine whether any representation is false, inaccurate or misleading. You consent to allow the Company to conduct such investigation and we reserve the right to refuse and/or terminate your membership due to any breach of warranty or your representation proves to be false, inaccurate or misleading.",
  },
  {
    category: "subheader",
    content:
      "3.6. If you are required to purchase a subscription, the technical aspects and features of the Service you may access and use are defined and controlled by your selected subscription, including without limitation, connection, metric, transfer, and other parameters. Exceeding such limits may result in your account being limited or restricted unless and until your subscription is upgraded.",
  },
  {
    category: "subheader",
    content:
      "3.7. The Company reserves the right to revise the features of the Service or pricing policy including the method or manner in which the Company charges for the Service from time to time but will comply with the notice requirements set out in the section titled “Notice”.",
  },
  { category: "header", content: "Payment" },
  {
    category: "subheader",
    content:
      "3.8. All payments will be facilitated either directly via the Company’s payment processing system, the Company’s third-party payment processing service or an associated payment processor. The Company may seek authorization of your credit card to validate your ability to pay any applicable charges prior to any purchase. If the Company seeks such authorization, please note that the authorization is not a charge but may reduce your available credit by the authorization amount until your bank's next processing cycle.",
  },
  {
    category: "subheader",
    content:
      "3.9. If the Company receives payment for an incorrect amount or non-specified currency payment, the Company will endeavor to notify you of such incorrect payment. If such incorrect payment is not rectified within thirty (30) days of providing such notice, the Company reserves the right to modify, restrict or discontinue any portion of the Service. You acknowledge and agree that the Company will not be liable to you or any third party for any modification, restriction or discontinuance of the Service.",
  },
  {
    category: "subheader",
    content:
      "3.10. It is your responsibility to determine what taxes apply to your use of the Service and collect, report and remit the correct amounts of tax to the appropriate tax authority, if applicable. The Company is not responsible for determining whether taxes apply to your use of the Service or for collecting, reporting or remitting any taxes or any information related thereto arising out of or resulting from your use of the Service.",
  },
  {
    category: "subheader",
    content:
      "3.11. The Company protects its business and credit card processors, banks and other institutions providing related services to the Company and Member from fraudulent credit card chargeback. A credit card chargeback is fraudulent if the credit card holder disputes a charge to a legitimate charge for services the holder has purchased. You understand and agree that in the event you attempt to create a fraudulent credit card chargeback, the Company will work with the relevant credit card processor, bank or other institution and law enforcement authorities to investigate the matter by providing details about the user profiles, card authentication and communications with or related to the Service or other members. If a payment is subject to investigation by the Company or a chargeback by the issuing bank, a hold will be placed on the payment and the account may be limited until such investigation or chargeback is resolved.",
  },
  { category: "header", content: "Security" },
  {
    category: "subheader",
    content:
      "3.12. The Company uses industry-standard methods to protect the security of accounts and data from unauthorized access, use or disclosure. However, the Company cannot fully defend against all potential security risks. You are solely responsible for maintaining the security of your account and routinely modifying your password to prevent unauthorized access. You also agree to immediately notify us of any unauthorized access, use or disclosure of your user account and assorted data or any breach of security. Accordingly, the Company hereby disclaims, to the fullest extent of applicable law, any liability for any loss or damage arising from any unauthorized access, use or disclosure of your user account and assorted data.",
  },
  {
    category: "subheader",
    content:
      "3.13. You agree to the Company’s collection, use, distribution, transmission, storage and retrieval of certain information concerning your use of the Service, including, without limitation, technical, diagnostic or service level information that the Company’s engineers and technicians may use to improve, enhance and upgrade the Service.",
  },
  { category: "header", content: "4. TERM & TERMINATION" },
  {
    category: "subheader",
    content:
      "4.1. This Agreement is effective immediately upon your access to or use of the Service for new users and upon the publishing date of any subsequent modifications, revisions or amendments for all current users.",
  },
  {
    category: "subheader",
    content:
      "4.2. You may terminate your use of the Service at any time and for any reason upon our receipt of your notice of termination. You must provide such notice to the Company via email requesting that your account be canceled and that all information contained therein be deleted. Upon receiving such request, the Company will use commercially reasonable efforts to delete your information. Notwithstanding the foregoing, however, please note that your information including your message history will be left intact and any users which you have previously interacted with will still be able to access and view the messages you have exchanged, and it also may be retained on the Company’s backup systems for some time in accordance with the Company’s Privacy Policy.",
  },
  {
    category: "subheader",
    content:
      "4.3. The Company may terminate or suspend your use or access of the Service, with or without notice, if :",
  },
  {
    category: "point",
    content: "(a) we received a complaint or numerous complaints about you;",
  },
  {
    category: "point",
    content: "(b) your account has been inactive for more three (3) months;",
  },
  {
    category: "point",
    content:
      "(c) you breach this Agreement or any of the terms contained therein including any guidelines imposed by us;",
  },
  {
    category: "point",
    content:
      "(d) the purpose of entering into this Agreement is not for the purpose of using the Services but to obtain personal information of other users, sell goods or services not approved by us, or in any manner, misuse the Platform.",
  },
  { category: "point", content: "(e) where required by law; or" },
  {
    category: "point",
    content:
      "(f) where we reasonably consider there has been a breach of this Agreement or any of the terms contained therein or a breach of applicable laws.",
  },
  {
    category: "subheader",
    content:
      "4.4. You acknowledge and agree that the Company is not required, and may be prohibited, from disclosing to you the reason for termination or suspension of your access to the Service. Further, you understand and agree that such termination may be effected immediately and without prior notice.",
  },
  {
    category: "subheader",
    content:
      "4.5. The Company may modify or terminate, either temporarily or permanently, your Service, or any portion thereof, at any time and at its sole discretion. You acknowledge and agree that the Company will not be liable to you or any third party for any costs or damages of any kind or resulting from any modification or termination of your access to the Service.",
  },
  {
    category: "subheader",
    content:
      "4.6. In event of termination or suspension of access to your Service by the Company, you will not be entitled to any refund of unused subscription fees or unused credits.",
  },
  {
    category: "subheader",
    content:
      "4.7. These terms are still applicable after termination of your Service.",
  },
  { category: "header", content: "5. USER INFORMATION" },
  {
    category: "subheader",
    content:
      "5.1. You may provide certain information to or via the Service, including information you use to register and create an account (collectively referred as “User Information”). User Information will be stored on the Company’s servers. You consent to such storage and the Company’s use of User Information to create an account that will allow you to use the Service. You are solely responsible for User Information and hereby expressly acknowledge and agree that the Company acts only as a passive conduit for such data.",
  },
  {
    category: "subheader",
    content:
      "5.2. When you use the Service, you agree to provide accurate, current and complete information and to maintain and keep it accurate, current and complete at all times. You agree that the Company and third parties may rely on User Information as accurate, current and complete. You acknowledge that if any information supplied by you is untrue, inaccurate, not current or incomplete in any respect, the Company has the right to immediately terminate this Agreement and your use of the Service.",
  },
  {
    category: "subheader",
    content:
      "5.3. The Company retains the right, in its sole and absolute discretion, to bar your use of the Service in the future for any or no reason. Following any such termination, this Agreement will remain in effect and any portions that, by their nature, survive termination, will survive and remain in full force and effect.",
  },
  {
    category: "subheader",
    content:
      "5.4. The Company may examine User Information prior to, during or following receipt and, at its sole discretion, prevent, cease or reject transmission of inappropriate or otherwise inadequate or erroneous data. The Company does not guarantee that User Information will be successfully transmitted to or received by the Company. User Information or any other information or data transmitted or distributed via the Service does not reflect the views of the Company and the Company does not warrant the validity, reliability, accuracy, legality, suitability or current nature thereof. The Company has no duty to check, investigate or verify the accuracy of any User Information or ensure truthful online or real-world user-to-user interactions or communications.",
  },
  { category: "header", content: "6.      THIRD-PARTY SERVICES" },
  {
    category: "subheader",
    content:
      "6.1. As part of the functionality of the Service, the Service may contain advertisements and promotions offered by third parties and links to other websites or resources (collectively referred as “Third Party Service” or “Third Party Services”). The Company does not control Third Party Services and make no representations or warranties about it. The Company does not endorse or accept any responsibility of the Third Party Services. Unless otherwise specified herein, any content you provide to or store with any Third Party Service is beyond the control of the Company and the Company cannot and will not be liable for any use of User Information you submit to any Third-Party Service.",
  },
  {
    category: "subheader",
    content:
      "6.2. Your relationship with Third Party Services is governed solely by the applicable contractual agreement between you and the Third Party.",
  },
  {
    category: "subheader",
    content:
      "6.3. The Company makes no effort to review content provided to Third Party Services or contractual agreements you execute therewith for any purpose, including without limitation, accuracy, legality or non-infringement. The Company is not responsible in any way for your interaction with any third party or Third Party Service.",
  },
  { category: "header", content: "7. REPRESENTATIONS & WARRANTIES" },
  {
    category: "subehader",
    content:
      "7.1. By using the Platforms or Service, you expressly represent, undertake and warrant that:-",
  },
  {
    category: "point",
    content:
      "(1) you are at least eighteen (18) years old and otherwise have the right, authority and capacity to enter into this Agreement;",
  },
  {
    category: "point",
    content:
      "(2) you are not a person who is barred or prohibited from using the Service any jurisdiction;",
  },
  {
    category: "point",
    content:
      "(3) you have never been accused, charged or convicted of any offence including, but not limited to, assault including sexual assault, battery, harassment including sexual harassment, rape, outrage of modesty, cheating, theft, gang robbery, among others ;",
  },
  {
    category: "point",
    content:
      "(4) you will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations; and",
  },
  {
    category: "point",
    content:
      "(5) you will not allow any minor (below the age of eighteen (18)) to access to the Platforms or Service, including taking all precautions and implementing all parental control protections on your computer or other electronic devices, and hereby commit to inform us immediately should you become aware of a minor using the Service.",
  },
  {
    category: "subheader",
    content:
      "7.2. You may not authorize others to use your account and may not assign or otherwise transfer your account to any third party. Further, your participation in using the Service is for your sole personal use only and you warrant that you will not use the Service for any commercial purpose or exchange any consideration, monetary or otherwise, via the Platforms or Service for any services of a sexual or illicit nature, including without limitation, pornography, obscenity, prostitution, human trafficking, slavery, financial fraud, collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email and unauthorised framing of or linking to the Platforms. Without limitation to the foregoing acts, the Company may investigate any unauthorized, illegal or illicit use of the Platforms or Service and, in its sole and absolute discretion, terminate the user account of any user engaging in such activities and seek all available remedies, including without limitation, civil, criminal, and equitable relief.",
  },
  {
    category: "subheader",
    content:
      "7.3. If you engage in illegal activities via the Service, such usage will be reported to applicable authorities and the Company will assist in criminal or civil actions that may be initiated against you as a result.",
  },
  {
    category: "subheader",
    content:
      "7.4. You may only access the Service using authorized means. It is your responsibility to ensure adequate access to the Internet and requisite capacity to use the Service. Further, it is your responsibility to ensure that you have downloaded the correct version of applications necessary to the use of the Service, if applicable. The Company is not liable if you do not have compatible connections, devices or applications or if you have downloaded incorrect or erroneous versions of software or materials containing viruses or other harmful data or instructions. The Company reserves the right to terminate this Agreement should you use the Service with an incompatible or unauthorized connection, device or application.",
  },
  { category: "header", content: "8.      USER CONDUCT" },
  {
    category: "subheader",
    content:
      "8.1. In your use of the Service, you agree to act responsibly in a manner demonstrating the exercise of good judgment. You agree and warrant that YOU WILL NOT:",
  },
  {
    category: "point",
    content:
      "Use the Service or any information displayed within the service to harass, abuse, defame, threaten, stalk or defraud other users;",
  },
  {
    category: "point",
    content:
      "Use the Service for any unlawful purpose or encourage, promote, facilitate or instruct others to do so, or violate the privacy or other rights of other users;",
  },
  {
    category: "point",
    content: "Use the Service to cause nuisance or inconvenience;",
  },
  {
    category: "point",
    content:
      "Include offensive, pornographic, sexually explicit, obscene, violent materials, or materials that are harmful in the personal profile of the Service, or transmitting content to other users using the Service;",
  },
  {
    category: "point",
    content:
      "Post any content within the Service that promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;",
  },
  {
    category: "point",
    content:
      "Promote hate speech, threatening, sexually explicit or pornographic incites violence or contains nudity or graphic or gratuitous violence within the Service;",
  },
  {
    category: "point",
    content:
      "Include material in the personal profile page which contains images, photographs, audio or video of any person under the age of eighteen (18);",
  },
  {
    category: "point",
    content:
      "Post, upload or transmit false, misleading or illegal information or content to the Service;",
  },
  {
    category: "point",
    content:
      "Impersonate any person or entity or post any images of another person without his or her permission;",
  },
  { category: "point", content: "Send spam or unsolicited messages;" },
  {
    category: "point",
    content:
      "Create, control or possess more than one user account, or use a name that is not yours or any temporary email address or phone number or other false information, or we have already suspended or terminated your account, unless you have our permission;",
  },
  {
    category: "point",
    content: "Send or receive fraudulent or potentially fraudulent funds;",
  },
  {
    category: "point",
    content:
      "Collect, store, upload or publish any personal data or information about other users without their consent or other than in the course of the permitted use of the Service;",
  },
  {
    category: "point",
    content:
      "Provide false information or identification evidence to us upon request;",
  },
  {
    category: "point",
    content:
      "Use the Service to match one user with another person, without our authorisation;",
  },
  {
    category: "point",
    content:
      "Infringe the rights of any third party, including without limitation, intellectual property, privacy, publicity or contractual rights, in any manner;",
  },
  {
    category: "point",
    content:
      "Impair the proper operation of any network within our Service and Platform;",
  },
  {
    category: "point",
    content:
      "Use the Service for any use except your own and will not resell or attempt to resell any part of the Service to any third party;",
  },
  {
    category: "point",
    content: "Use unauthorised Wi-Fi access points or mobile data accounts;",
  },
  {
    category: "point",
    content: "Use any mechanism to mask your identity or location;",
  },
  {
    category: "point",
    content:
      "Send messages using the Service which do not correctly identify the sender and will not alter the attribution of origin in email messages or postings;",
  },
  {
    category: "point",
    content:
      "Breach this Agreement, card processing or other agreements you have entered into with the Company or third parties;",
  },
  {
    category: "point",
    content:
      "Imply or state, directly or indirectly, that you are affiliated with or endorsed by the Company without express written permission;",
  },
  {
    category: "point",
    content:
      "Violate laws, statutes, ordinances or regulations, including without limitation, those governing safety, health, medical records, financial services, consumer protection, unfair competition, anti-discrimination or false advertising;",
  },
  {
    category: "point",
    content:
      "Use the Services for any commercial or non-private use, such as the sale or advertisement of goods or services;",
  },
  {
    category: "point",
    content: "Operate or attempt to operate a business via the Service;",
  },
  {
    category: "point",
    content:
      "Solicit, manage, organize, accept or engage in illicit activity, including without limitation, gambling, money laundering, prostitution, human trafficking or escort services;",
  },
  {
    category: "point",
    content:
      "Contact third parties or users of the Service without their express prior written consent;",
  },
  {
    category: "point",
    content:
      "Cause to attempt complaints, disputes, claims, chargebacks, fees, penalties or other liability;",
  },
  {
    category: "point",
    content:
      "License, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service in any way;",
  },
  {
    category: "point",
    content: "Modify or make derivative works based upon the Service;",
  },
  {
    category: "point",
    content:
      'Create "links" to the Service, "frame" or "mirror" any part thereof on any server, wireless or Internet device;',
  },
  {
    category: "point",
    content:
      "Use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or exploit the any portion of the Platforms or Service or any software used on or for the Service;",
  },
  {
    category: "point",
    content: "Reverse engineer any portion of the Service;",
  },
  { category: "point", content: "Build a competitive product or service;" },
  {
    category: "point",
    content: "Build a product using ideas or features similar to the Service;",
  },
  {
    category: "point",
    content:
      "Copy ideas or features of the Service or launch an automated program or script, including without limitation, spiders, crawlers, robots, bots, ants, indexers, viruses, worms or any program which make multiple server requests or unduly burden the operation of the Service;",
  },
  {
    category: "point",
    content:
      "Send or store infringing, obscene, threatening, libellous, unlawful or tortious material, including without limitation, material harmful to children or in violation of privacy rights;",
  },
  {
    category: "point",
    content:
      "Send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs;",
  },
  {
    category: "point",
    content:
      "Interfere with or disrupt the integrity or performance of the Service or data contained therein;",
  },
  {
    category: "point",
    content: "Attempt to gain unauthorised access to the Service;",
  },
  { category: "point", content: "Disrupt our service in any way;" },
  {
    category: "point",
    content:
      "Advocates, promotes or assists any unlawful act such as (by way of example only) copyright infringement or computer misuse; or",
  },
  {
    category: "point",
    content: "Assist any third party in doing any of the foregoing.",
  },
  {
    category: "subheader",
    content:
      "8.2. Any of the foregoing is a violation of the Company’s valuable and proprietary rights that may subject you to prosecution and civil damages. The Company will fully investigate and prosecute any such actions to the fullest extent of the law and may involve and cooperate with law enforcement authorities.",
  },
  {
    category: "subheader",
    content:
      "8.3. You acknowledge that the Company has no obligation to monitor your access to or use of the Service or to review or edit any User Information or other data you transmit or submit to the Service, but has the right to do so for the purpose of operating the Service, to ensure your compliance with this Agreement or to comply with applicable law or the order of a court, administrative agency or other governmental body. The Company reserves the right to remove or disable access, at any time and without notice, to any information that the Company deems to be in violation of this Agreement or otherwise harmful to the Service or third parties.",
  },
  { category: "header", content: "9. CONSENT & GENERAL RELEASE" },
  {
    category: "subheader",
    content:
      "9.1. The Service is a passive source of information for your recreational and non-commercial use. The Company does not monitor user conduct or behaviour and cannot and will not be responsible for the consequences of your use of the Service. You hereby agree and consent to your use of the Service and all the accompanying risk of harm and injury that may result therefrom, including without limitation, online or real-world interactions with other users of the Service, private financial arrangements or transfers between users of the Service, including financial gains or losses, and any resulting physical harm and injury that occurs to users by nature of their online or real-world interactions, meetings, relationships or lack thereof, without regard for the extent and severity of such harm and injuries.",
  },
  {
    category: "subheader",
    content:
      "9.2. You hereby hold harmless and forever release, waive and discharge the Company from all liability, damages, personal injuries and other liability of any kind or nature whatsoever from your use of the Service, your submission of any User Information or financial data to the Company, your online and real-world interactions with any third party or users of the Service or by reason of any physical or sexual assault, accident, illness, injury, death or damage to yourself, third parties or users of the Service or the loss or destruction of any property arising out of or relating to the Company, the Service, Third Party Services, your reliance thereon or use thereof.",
  },
  {
    category: "subheader",
    content:
      "9.3. You acknowledge and agree that the Company makes no guarantees or warranties, express or implied, regarding your suitability or compatibility with users you may meet via the Service nor the personality, criminal background or safety thereof. You warrant that you will take all necessary and prudent precautions when using the Service or in online or real-world interactions with any third party or users of the Service.",
  },
  {
    category: "subheader",
    content:
      "9.4. You agree that the general release contained in this Agreement is binding on you, your heirs, executors, administrators, family members and all persons, users or third parties pursuing a claim on your behalf, on behalf of your estate or against you or your estate. By using the Service, you affirm you have reviewed the language of the general release contained in this Agreement and affirmatively consent and agree to all of its terms.",
  },
  { category: "header", content: "10. INTELLECTUAL PROPERTY" },
  {
    category: "subheader",
    content:
      "10.1. The Company owns and retains ownership in the Service and all associated intellectual property rights contained therein. The copyright to the contents of the Platforms is wholly owned by or licensed to the Company. These include, but are not limited to, articles, editorial contents, publications, illustrations, graphics and logos made available on the Platforms.",
  },
  {
    category: "subheader",
    content:
      "10.2. The Company’s trade name, trademarks, domain names, logos, character and service marks displayed on the Platforms or associated with the Service (collectively referred as “Trade Marks”) are proprietary to the Company. No right or license thereto is granted nor contemplated by this Agreement to use the Trade Marks or any other contents on the Platforms without our prior written permission or except as expressly provided in the Agreement.",
  },
  {
    category: "subheader",
    content:
      "10.3. By transmitting or submitting User Information or other data to the Service, you thereby grant or have the right to grant to the Company a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, use, view, copy, adapt, modify, reproduce, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast and otherwise exploit such content on, through or by means of the Service. The Company does not claim any ownership rights in such content and nothing in this Agreement will be deemed to restrict any rights you may have to use and exploit any portion thereof.",
  },
  {
    category: "subheader",
    content:
      "10.4. You acknowledge and agree that you are solely responsible for the content you make available through the Service. You represent and warrant that you are either the sole and exclusive owner of all such content or have all rights, licenses, consents, and releases necessary to grant a license to the Company to use such content and neither the content nor the posting, uploading, publication, submission, transmittal nor the Company’s use thereof will infringe, misappropriate or violate any third party’s patent, copyright, trademark, trade secret, moral, publicity, privacy or other rights or result in the violation of applicable laws or regulations.",
  },
  {
    category: "subheader",
    content:
      "10.5. The Company respects the intellectual property rights of others and expects you to do the same. The Company will, in appropriate circumstances and at its reasonable sole discretion, terminate use of and/or access to the Platforms or Service for any user who infringes or is believed to be infringing any of the intellectual property rights of others.",
  },
  {
    category: "subheader",
    content:
      "10.6. The Company and its licensors retain and own all right, title and interest, including without limitation, intellectual property rights, in the Service and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Service. This Agreement is neither a sale nor a license and does not convey any rights of ownership or license rights relating to the Service or any intellectual property rights owned by the Company.",
  },
  { category: "header", content: "11. COMMUNICATIONS" },
  {
    category: "subheader",
    content:
      "11.1. Email communications and text messages sent by the Company or for Third Party Services are designed to make your experience more efficient. By accepting the terms of this Agreement, you explicitly consent to receive email communications and text messages initiated by the Company, including without limitation, notifications, communications informing you about potential services, promotions and new or existing features. Standard text messaging rates charged by your mobile phone carrier will apply to text messages sent by the Company, if applicable. If you change your mobile carrier, notifications and communications may be deactivated for your user account and you will be required to reauthorise your new mobile phone number for such messages. The Company reserves the right to cancel notifications and communications at any time without notice.",
  },
  { category: "header", content: "12. INDEMNIFICATION" },
  {
    category: "subheader",
    content:
      "12.1. To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold the Company, its officers, directors, employees, agents, affiliates and any associated third parties harmless from and against any losses, costs, liabilities and expenses, including reasonable legal fees, arising out of or relating to, directly or indirectly, your use of the Platforms or Service; your breach of this Agreement or the documents it incorporates by reference; your violation of any applicable laws, rules or regulations; any allegation that any materials that you submit to the Company or transmit via the Service infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; and any other activities in connection with your use of the Platforms or Service. This indemnity will be applicable without regard to the negligence of any party, including any indemnified person, the Company or third parties with whom the Company does business.",
  },
  { category: "header", content: "13. DISCLAIMER OF WARRANTIES" },
  {
    category: "subheader",
    content:
      "13.1. YOU ACKNOWLEDGE AND AGREE THAT OUR SERVICE AND ALL CONTENT THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, RELIABILITY, TIMELINESS, SAFETY, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICE NOR ANY SERVICES OR PRODUCTS OBTAINED BY THIRD PARTIES VIA THE SERVICE. YOU ALSO ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT YOUR USE OF THE SERVICE OR ANY THIRD PARTY SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU TO THE MAXIMUM EXTENT PERMITTED BY LAW.",
  },
  {
    category: "subheader",
    content:
      "13.2. WHILST WE TAKE REASONABLE COMMERCIAL EFFORTS TO PROVIDE THE SERVICE TO YOU, THE COMPANY DOES NOT REPRESENT OR WARRANT THAT:",
  },
  {
    category: "point",
    content:
      "(a) THE USE OF THE SERVICE WILL BE SECURE, TIMELY, UNINTERRUPTED, ERROR-FREE OR OPERATE WITH OTHER HARDWARE, APPLICATIONS, SYSTEMS OR DATA;",
  },
  { category: "point", content: "(b) THE SERVICE WILL MEET REQUIREMENTS;" },
  {
    category: "point",
    content:
      "(c) ANY STORED DATA OR INFORMATION THAT YOU MAY OBTAIN ON THE PLATFORMS OR SERVICE WILL BE ACCURATE, UP TO DATE OR RELIABLE;",
  },
  {
    category: "point",
    content:
      "(d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY THE USER THROUGH THE SERVICE WILL MEET USER REQUIREMENTS OR EXPECTATIONS;",
  },
  {
    category: "point",
    content: "(e) ERRORS IN THE SERVICE WILL BE CORRECTED;",
  },
  {
    category: "point",
    content:
      "(f) THE SERVICE, EQUIPMENT OR SERVER THAT MAKE THE SERVICE AVAILABLE OR ANY FILE OR MATERIAL THAT YOU MAY DOWNLOAD FROM THE PLATFORMS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR",
  },
  {
    category: "point",
    content:
      "(g) THIRD PARTIES WILL NOT USE YOUR CONFIDENTIAL INFORMATION IN AN UNAUTHORISED MANNER.",
  },
  {
    category: "subheader",
    content:
      "13.3. THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, PROBLEMS OR TECHNICAL MALFUNCTION INHERENT IN THE USE OF THE PLATFORMS OR SERVICE ON THE INTERNET AND ELECTRONIC COMMUNICATIONS. THE COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, LOSS OR DAMAGES RESULTING THEREFROM.",
  },
  { category: "header", content: "14. LIMITATION OF LIABILITY" },
  {
    category: "subheader",
    content:
      "14.1. IN NO EVENT WILL THE COMPANY BE LIABLE TO ANYONE FOR INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND, INCLUDING WITHOUT LIMITATION, PERSONAL INJURY, LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER LOSS OF ECONOMIC ADVANTAGE OR BENEFITS. THE COMPANY WILL NOT BE LIABLE FOR ANY LOSS, CLAIM, DAMAGES OR INJURIES WHICH MAY BE INCURRED DUE TO USE OF THE PLATFORMS OR SERVICE, INCLUDING WITHOUT LIMITATION DAMAGES OR INJURIES ARISING OUT OF OR IN ANY WAY CONNECTED WITH OR RESULTING FROM THE PLATFORMS, SERVICE, PRODUCTS OR SERVICES, USE OR INABILITY TO USE THE SERVICE, RELIANCE PLACED BY THE USER ON COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING OR INFORMATION CONTAINED WITHIN THE SERVICE OR RESULTING FROM ANY RELATIONSHIP BETWEEN YOU AND THIRD PARTY SERVICES, PRODUCTS, ADVERTISERS OR SPONSORS, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
  },
  {
    category: "subheader",
    content:
      "14.2. WE WILL NOT BE LIABLE FOR ANY FAILURE OR DELAY IN PERFORMING UNDER THIS AGREEMENT WHERE SUCH FAILURE OR DELAY IS DUE TO CAUSES BEYOND OUR REASONABLE CONTROL.",
  },
  {
    category: "subheader",
    content:
      "14.3. THE COMPANY MAY INTRODUCE YOU TO THIRD PARTIES FOR PURPOSES OF PROVIDING ACCESS TO THIRD PARTY SERVICES. THE COMPANY WILL NOT ASSESS THE SUITABILITY, LEGALITY OR ABILITY OF ANY THIRD PARTY OR THIRD PARTY SERVICE AND YOU HEREBY EXPRESSLY WAIVE, RELEASE AND FOREVER DISCHARGE THE COMPANY FROM ALL LIABILITY, CLAIMS, DAMAGES AND INJURIES ARISING OUT OF OR IN ANY WAY CONNECTED WTIH OR RESULTING FROM THE SERVICE, THIRD PARTIES AND THIRD PARTY SERVICES. YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. THE COMPANY WILL NOT BE A PARTY TO DISPUTES, NEGOTIATIONS OF DISPUTES, LITIGATION, MEDIATION OR ARBITRATION BETWEEN YOU AND THIRD PARTIES. THE COMPANY CANNOT AND WILL NOT PLAY ANY ROLE IN MANAGING PAYMENTS BETWEEN YOU AND THIRD PARTIES. RESPONSIBILITY FOR DECISIONS YOU MAKE REGARDING THE SERVICE AND THIRD PARTY SERVICES, WITH ALL ASSOCIATED IMPLICATIONS AND CONSEQUENCES, REST SOLELY WITH YOU. THE COMPANY WILL NOT ASSESS THE LEGALITY, LIABILITY OR ABILITY OF THIRD PARTIES RESULTING FROM ANY NEGLIGENCE, CLAIMED OR OTHERWISE, NOR ASSESS THE SUITABILITY, LEGALITY OR ABILITY OF THIRD PARTIES TO PAY DAMAGES RESULTING THEREFROM AND YOU HEREBY EXPRESSLY WAIVE, RELEASE AND FOREVER DISCHARGE THE COMPANY FROM ALL LIABILITY, CLAIMS, CAUSES OF ACTION, DAMAGES AND INJURIES ARISING OUT OF OR IN ANY WAY CONNECTED WTIH OR RESULTING FROM THE SERVICE, USE OF THE SERVICE OR THIRD PARTIES AND THIRD PARTY SERVICES INTRODUCED TO YOU BY THE SERVICE OR THE COMPANY.",
  },
  {
    category: "subheader",
    content:
      "14.4. QUALITY OF SERVICE IS THE SOLE RESPONSIBILITY OF THE THIRD PARTY THAT ULTIMATELY PROVIDES THE SERVICE. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT, BY USING THE SERVICE, YOU MAY BE EXPOSED TO PRODUCTS, SERVICES, SITUATIONS, SCENARIOS OR INDIVIDUALS THAT ARE POTENTIALLY DANGEROUS, INJURIOUS, PHYSICALLY DISABLING OR CHALLENGING, OFFENSIVE, HARMFUL TO MINORS, UNSAFE OR OTHERWISE OBJECTIONABLE AND YOU USE THE SERVICE AT YOUR OWN RISK.",
  },
  {
    category: "subheader",
    content:
      "14.5. THE SERVICE IS A PASSIVE INFORMATION CONDUIT ENABLING USERS TO POST PERSONAL IDENTIFYING INFORMATION FOR PURPOSES OF MEETING AND DATING OTHER USERS. THE COMPANY DOES NOT AND WILL NOT REVIEW OR VERIFY ANY INFORMATION ACCESSED VIA THE SERVICE, INCLUDING WITHOUT LIMITATION, THE TRUE OR VERIFIED IDENTITY OF ANY USER.",
  },
  { category: "header", content: "15. VERIFICATION" },
  {
    category: "subheader",
    content:
      "15.1. To ensure that our members are safe when using our Platform or Services, we may require you or other users to conduct a verification test. The purpose of the verification test is to verify your identity. In this regard, the verification may be done by way of, among others, provision of personal documents, bank account statements, and video and audio conferencing. Verification may be done by us or our service provider, or even another member.",
  },
  {
    category: "subheader",
    content:
      "15.2. The Service may provide a Third Party Service offering self-background screening based on User Information provided to the Third Party Service. If such a Third Party Service is offered, Users who process their User Information with the applicable Third Party Service will have the option to display a “Verified” or other distinguishable mark or logo on their user profile. The Company makes no effort to review the processing of such Third Party Service for accuracy or legitimacy and is not responsible in any way for your reliance thereon.",
  },
  {
    category: "header",
    content: "16. CONFIDENTIALITY OF OTHER USERS’ IDENTITY",
  },
  {
    category: "subheader",
    content:
      "16.1. Unless expressly consented by the Company, you are required to keep all other members or users’ information confidential. You are not allowed to publish their information to the public, including, but not limited to, their user details, photographs, chat logs, and social media accounts.",
  },
  { category: "header", content: "17. TAKE DOWN" },
  {
    category: "subheader",
    content:
      "17.1. If you believe that the material contained within the Platforms or Service violates your intellectual property rights, please contact the Company. Any notice transmitted to the Company must include all of the following information:",
  },
  {
    category: "point",
    content:
      "(a) an electronic or physical signature of a person authorised to act on behalf of the owner of an exclusive right that is allegedly infringed;",
  },
  {
    category: "point",
    content:
      "(b) identification of the copyrighted and/or trade mark claimed to have been infringed;",
  },
  {
    category: "point",
    content:
      "(c) identification of the material claimed to be infringing and the exact location of such material within the Platforms or Service;",
  },
  {
    category: "point",
    content:
      "(d) information reasonably sufficient to permit the Company to contact you, such as physical address, telephone number or email address;",
  },
  {
    category: "point",
    content:
      "(e) a statement that you have a good faith belief that use of the material in the stated manner is not authorised by the copyright or trade mark owner, its agent or the law; and",
  },
  {
    category: "point",
    content:
      "(f) a statement made under penalty of perjury, that you are authorised to act on behalf of the owner of an exclusive right that is allegedly infringed.",
  },
  {
    category: "subheader",
    content:
      "17.2. If you, as our user believe that any content that was removed or to which access was disabled is not infringing, or if you believe you have the right to post and use such content from the copyright owner, the copyright owner's agent, or pursuant to the law, you may send a counter-notice containing the following information to us:",
  },
  {
    category: "point",
    content: "(a) Your physical or electronic signature;",
  },
  {
    category: "point",
    content:
      "(b) Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;",
  },
  {
    category: "point",
    content:
      "(c) a statement under penalty of perjury that you believe in good faith that the content was removed or disabled as a result of mistake or a misidentification of the content; and",
  },
  {
    category: "point",
    content:
      "(d) your name, address, telephone number, e-mail address and a statement that you consent to the jurisdiction of the Court in which your address is located, or if your address is located outside Malaysia , for any judicial district in we are located, and you will accept service of process from the person who provided notification of the alleged infringement.",
  },
  {
    category: "point",
    content:
      "If a counter-notice is received by us, we may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in ten (10) business days.",
  },
  {
    category: "point",
    content:
      "Unless the copyright owner files an action seeking a court order against the user, the removed content may be replaced, or access to it restored, in ten (10) business days after receipt of the counter-notice, at our discretion.",
  },
  { category: "header", content: "18. NOTICE" },
  {
    category: "subheader",
    content:
      "18.1 Unless provided otherwise in this Agreement, all notices to the Company shall be in writing and delivered to the Company’s office address as given under the section titled “Contact”. The Company may give notice via the Service, email to your email address or written communication sent by mail to your address. Email notices shall be deemed received 24 hours after the email is sent unless the sender is notified that the email address is invalid. All other notices shall be deemed received when delivered.",
  },
  { category: "header", content: "19. ASSIGNMENT" },
  {
    category: "subheader",
    content:
      "19.1 This Agreement may not be assigned by you to any third party. The Company has the right to assign any or all its rights and obligations under this Agreement or to the Service, without notice or your consent, to a parent company, subsidiary, acquirer of assets or successor by merger. Any purported assignment in violation of this provision will be void. In the event that the Company’s obligations under this Agreement are assumed by a third party, the Company shall be relieved of any and all liability, claims, charges or damages under this Agreement.",
  },
  { category: "header", content: "20. EXPORT CONTROL & RESTRICTIONS" },
  {
    category: "subheader",
    content:
      "20.1 By using the Service, you represent and warrant that You have the right, authority and capacity to enter into this Agree, and abide by all of the terms and conditions of this agreement.",
  },
  {
    category: "subheader",
    content:
      "20.2 You agree to fully comply with all applicable export laws and regulations, including, but not limited to Malaysia export control laws to ensure neither the Service, technical data related thereto nor product thereof is exported or re-exported, directly or indirectly, in violation of or used for any purpose prohibited by such laws and regulations.",
  },
  {
    category: "subheader",
    content:
      "20.3 You also agree to represent and warrant that you are not located in a country subject to government embargo or designated by any government as a “terrorist supporting” country and is not listed on any government list of prohibited or restricted jurisdictions.",
  },
  {
    category: "header",
    content: "21. ONE-CLICK PAYMENT/CLICK-TO-ACCEPT POLICY",
  },
  {
    category: "subheader",
    content:
      '21.1 By ticking the relevant tick-box(es) and/or clicking the "Pay","Proceed to Payment", or "Proceed to Secure Payment" button, you express full consent to this Agreement and authorize our Payment Merchants and its payment processing service providers to store your card credentials and initiate transactions on your behalf to purchase membership subscriptions and/or one-time payments of any level, and with no need to reinsert your credit/debit card.',
  },
  {
    category: "subheader",
    content:
      "21.2 You agree to be bound by any rules your financial institution requiring for pre-authorized electronic funds transfers, and/or that your debit or credit card issuer requires for pre-authorized debit or credit card transactions. You are responsible for all fees charged by your financial institution associated with the pre-authorized payment option.",
  },
  {
    category: "subheader",
    content:
      "21.3 The Company will bear no liability or responsibility for any losses of any kind that you may incur as a result of payment on items incorrectly billed or for any delay in the actual date on which your credit/debit card is charged.",
  },
  {
    category: "subheader",
    content:
      "21.4 Transaction currency is processed in United States Dollars (USD).",
  },
  {
    category: "subheader",
    content:
      "21.5 You have the right to terminate your membership subscriptions at any time. Refer to the Cancellation Policy Section for more information.",
  },
  {
    category: "subheader",
    content:
      "21.6 In case of any inquiries regarding One-Click payment/Click-To-Accept policy, contact support@candybook.com.my or our Support feature on by logging into the Platform / Service.",
  },
  { category: "header", content: "22. CANCELLATION POLICY" },
  {
    category: "subheader",
    content:
      "22.1 You may cancel your paid membership subscription at any point in time. To cancel your membership subscription, you have to contact us at support@candybook.com.my and inform us of the cancellation reason, and proof of purchase. We will process your cancellation request, and you will be informed via email within 48 working hours.",
  },
  {
    category: "subheader",
    content:
      "22.2 If the Company terminates your paid membership subscription, you will not be entitled to any refund of unused or remaining subscription fees and/or credits.",
  },
  { category: "header", content: "23. REFUND POLICY" },
  {
    category: "subheader",
    content:
      "23.1 Refunds are ONLY APPLICABLE if your use of our Platform or Service is inline in this Agreement, and is in good standing. We will assess your use of our Platform or Service and determine the amount of refund accordingly. Refunds will be forfeited if this Agreement has been breached.",
  },
  {
    category: "subheader",
    content:
      "23.2 Unless otherwise stated, the Company does not entertain any other refunds. Payment made will be valid up till the period agreed. Payments made are non-transferable to another party and are only applicable for the named holder of the account.",
  },
  {
    category: "subheader",
    content:
      "23.3 If the Company is alerted that User has sought and received a Refund or Chargeback, Company will take additional actions. Any purchases within the Platform which is subsequently subject to a Refund or Chargeback may result in User's account being immediately and platform excluded from the Platform.",
  },
  { category: "header", content: "24. PRIVACY" },
  {
    category: "subheader",
    content:
      "24.1 When you use the Platforms or Service, the Company will collect, store and use the information as described in the Privacy and Personal Data Protection Policy at https://candybook.com.my/privacy. If you do not agree to such provisions, please do not use the Platforms or Service.",
  },
  { category: "header", content: "25. GOVERNING LAWS & DISPUTE RESOLUTION" },
  {
    category: "subheader",
    content:
      "25.1 The laws of Malaysia shall apply exclusively to this Agreement and all matters related hereto. The Parties agree to submit disputes arising out of or in connection with this Agreement to the Malaysian courts in Kuala Lumpur .",
  },
  {
    category: "subheader",
    content:
      "25.2 You agree that any disputes arising out of, or related to, the Website, the Service, this Agreement, and/or any policies or practices as stated by the Company, shall be governed by the laws of Malaysia , without regard to principle of conflicts of law.",
  },
  { category: "header", content: "26. GENERAL PROVISIONS" },
  {
    category: "point",
    content:
      "(1) Information and Service that are provided on the Platforms in a language other than English are only provided as a convenience to you when available. All services, obligations of the Company and communication between the parties in respect with this Agreement will be in the English Language. In the event the translation on the Platforms contains errors, omissions or discrepancies, the English version shall prevail.",
  },
  {
    category: "point",
    content:
      "(2) Headings are for reference purposes only and do not limit the scope or extent of such section.",
  },
  {
    category: "point",
    content:
      "(3) No joint venture, partnership, employment or agency relationship exists between you, the Company or any third party as a result of this Agreement or your use of the Platforms or Service.",
  },
  {
    category: "point",
    content:
      "(4) If any provision of this Agreement is found to be, void or for any other reason invalid or unenforceable under applicable law, such provision will be deemed severable from the Agreement and the remaining provisions will be enforced to the fullest extent of applicable law.",
  },
  {
    category: "point",
    content:
      "(5) The failure of the Company to enforce any right or provision herein will not constitute a waiver of such right or provision unless explicitly acknowledged and agreed to by the Company in writing.",
  },
  {
    category: "point",
    content:
      "(6) This Agreement constitutes the entire agreement between the parties and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or oral, regarding the subject matter hereof.",
  },
  { category: "header", content: "CONTACT" },
  {
    category: "point",
    content:
      "If you have feedback, comments or questions about this Terms of Use, please contact us at:-",
  },
  { category: "header", content: "Customer Support & General Inquiries" },
  { category: "point", content: "Sovrnt Ltd." },
  { category: "point", content: "(HE395258)" },
  { category: "point", content: "22 Arch. Makariou III," },
  {
    category: "point",
    content: "Avenue Makaria Centre, 4th Floor, Office 403",
  },
  { category: "point", content: "6016 Larnaca, Cyprus" },
  { category: "point", content: "Email: support@candybook.com.my" },
  { category: "point", content: "Dated: 1 February 2021" },
];

export default terms;