// General
import "./featured-in.scss";
// Assets
import BusinessInsider from "../../../assets/logo/featured-in/business-insider-logo.png";
import NewYorkPost from "../../../assets/logo/featured-in/new-york-post-logo.png";
import BBC from "../../../assets/logo/featured-in/bbc-news-logo.png";
import NBCNews from "../../../assets/logo/featured-in/nbc-news-logo.png";
import YahooNews from "../../../assets/logo/featured-in/yahoo-news-logo.png";
import TheStraitsTime from "../../../assets/logo/featured-in/the-straits-time-logo.png";
import FinancialTimes from "../../../assets/logo/featured-in/financial-times-logo.png";
import DailyMail from "../../../assets/logo/featured-in/daily-mail-logo.png";
import Vice from "../../../assets/logo/featured-in/vice-logo.png";
import SouthChinaMorningPost from "../../../assets/logo/featured-in/south-china-morning-post-logo.png";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const FeaturedIn = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-feature-in-subcomponent" className="feature-in-container">
      <div className="max-width-container">
        <div className="feature-label-container">
          <Divider className="feature-label">
            {t("landing.as_featured_in")}
          </Divider>
        </div>

        <div className="featured-company-container">
          <div className="company-logo-container">
            <img src={BusinessInsider} alt="business insider" />
          </div>

          <div className="company-logo-container">
            <img src={NewYorkPost} alt="new york post" />
          </div>

          <div className="company-logo-container">
            <img src={BBC} alt="bbc" />
          </div>

          <div className="company-logo-container">
            <img src={NBCNews} alt="nbc news" />
          </div>

          <div className="company-logo-container">
            <img src={YahooNews} alt="yahoo news" />
          </div>

          <div className="company-logo-container">
            <img src={TheStraitsTime} alt="the straights time" />
          </div>

          <div className="company-logo-container">
            <img src={FinancialTimes} alt="financial times" />
          </div>

          <div className="company-logo-container">
            <img src={DailyMail} alt="daily mail" />
          </div>

          <div className="company-logo-container">
            <img src={Vice} alt="vice" />
          </div>

          <div className="company-logo-container">
            <img src={SouthChinaMorningPost} alt="south china morning post" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedIn;
