// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
};

export const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { updateToken } = redirectSlice.actions;

export default redirectSlice.reducer;
