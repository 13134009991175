// General
import "./hero.scss";
import { Link } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
// Assets
import HeroImage from "../../../assets/others/stockphotos/hero.png";
import HeroImageV2 from "../../../assets/others/stockphotos/hero-v2.jpg";
import HeroVideo from "../../../assets/others/video/candybook-hero-video.mp4";
import BrandLogoUpper from "../../../assets/logo/candybook-uppercase.png";
// Static Data
import route from "../../../const/route";
// i18next
import { useTranslation } from "react-i18next";

const Hero = () => {
  // Redux variables
  const { queryString } = useSelector((state) => state.public);

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="landing-hero-subcomponent">
      <div className="hero-media-container">
        <img className="hero-image" src={HeroImageV2} />
        <video
          className="hero-video"
          src={HeroVideo}
          playsInline
          autoPlay={true}
          loop={true}
          muted={true}
        />
      </div>

      <div className="hero-description-container">
        <div className="mobile-center-container">
          <div className="text welcome">{t("landing.welcome_to")}</div>
          <div className="brand-logo-container">
            <img className="brand-logo" src={BrandLogoUpper} />
          </div>

          <div className="text premier-1">{t("landing.landing_title_1")}</div>

          <div className="text journey">{t("landing.landing_title_2")}</div>

          <div className="button-container">
            <Link
              className="join-button-container"
              to={{ pathname: route.join.path, search: queryString }}
            >
              <div className="join-button">{t("landing.join_free_now")}</div>
            </Link>

            <Link
              className="login-button-container"
              to={{ pathname: route.login.path, search: queryString }}
            >
              <div className="login-button">{t("landing.login")}</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
