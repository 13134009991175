const user = {
  lifestyle: {
    monthlySpendingHabit: [
      "Negotiable",
      "Minimal",
      "Practical",
      "Moderate",
      "Substantial",
      "High",
    ],
    netWorth: [
      "$100,000",
      "$250,000",
      "$500,000",
      "$750,000",
      "$1 million",
      "$2 million",
    ],
    annualIncome: [
      "$50,000",
      "$75,000",
      "$100,000",
      "$125,000",
      "$150,000",
      "$175,000",
      "$200,000",
      "$250,000",
      "$300,000",
      "$400,000",
      "$500,000",
      "$1 million",
      "$1 million ++",
    ],
    bodyType: [
      "Slim",
      "Athletic",
      "Average",
      "A Few Extra Pounds",
      "Full / Overweight",
      "Other",
    ],
    ethnicity: [
      "Asian",
      "Black / African Descent",
      "Latin / Hispanic",
      "East Indian",
      "Middle Eastern",
      "Mixed",
    ],
    eyeColor: ["Black", "Blue", "Brown", "Grey", "Green", "Hazel", "Other"],
    hairColor: [
      "Auburn",
      "Black",
      "Blonde",
      "Light Brown",
      "Dark Brown",
      "Grey",
      "Red",
      "White",
      "Other",
    ],
  },
  detail: {
    education: [
      "High School",
      "Some College",
      "Associates Degree",
      "Bachelors Degree",
      "Graduate Degree",
      "PhD / Post Doctoral",
    ],
    relationship: [
      "Single",
      "Divorced",
      "Separated",
      "Married But Looking",
      "Widowed",
    ],
    children: ["0", "1", "2", "3", "4", "5", "6+"],
    smoking: ["Non Smoker", "Light Smoker", "Heavy Smoker"],
    drinking: ["Non Drinker", "Social Drinker", "Heavy Drinker"],
  },
};

export default user;
